import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <div id="main" className="success">
        <section id="one">
            <div className="inner">
                <span className="hero">Thank You</span>
                <span className="details">
                    Our Team will reach out<br/>
                    to you in the next 10 minutes.<br/>
                    Our <Link to="/blog">articles</Link> would help you for a while.
                </span>
            </div>
        </section>
    </div>
  </Layout>
)

export default NotFoundPage
